exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-kontakt-js": () => import("./../../../src/pages/kontakt.js" /* webpackChunkName: "component---src-pages-kontakt-js" */),
  "component---src-pages-o-nas-js": () => import("./../../../src/pages/o-nas.js" /* webpackChunkName: "component---src-pages-o-nas-js" */),
  "component---src-pages-oferta-odpady-kabli-js": () => import("./../../../src/pages/oferta/odpady-kabli.js" /* webpackChunkName: "component---src-pages-oferta-odpady-kabli-js" */),
  "component---src-pages-oferta-odpady-katalizatorow-js": () => import("./../../../src/pages/oferta/odpady-katalizatorow.js" /* webpackChunkName: "component---src-pages-oferta-odpady-katalizatorow-js" */),
  "component---src-pages-oferta-odpady-metali-kolorowych-js": () => import("./../../../src/pages/oferta/odpady-metali-kolorowych.js" /* webpackChunkName: "component---src-pages-oferta-odpady-metali-kolorowych-js" */),
  "component---src-pages-oferta-odpady-weglika-spiekanego-js": () => import("./../../../src/pages/oferta/odpady-weglika-spiekanego.js" /* webpackChunkName: "component---src-pages-oferta-odpady-weglika-spiekanego-js" */),
  "component---src-pages-oferta-zlom-elektroniki-js": () => import("./../../../src/pages/oferta/zlom-elektroniki.js" /* webpackChunkName: "component---src-pages-oferta-zlom-elektroniki-js" */),
  "component---src-pages-oferta-zlom-stalowy-js": () => import("./../../../src/pages/oferta/zlom-stalowy.js" /* webpackChunkName: "component---src-pages-oferta-zlom-stalowy-js" */),
  "component---src-pages-polityka-prywatnosci-js": () => import("./../../../src/pages/polityka-prywatnosci.js" /* webpackChunkName: "component---src-pages-polityka-prywatnosci-js" */)
}

